import React from 'react'

function AboutSection() {
  return (
    <>
            <section id="about-1" className="bg-color-01 wide-60 about-section division">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-5 col-lg-6">
                <div className="img-block left-column pc-10 mb-40">
                  <img className="img-fluid" src="/assets/images/image-01.png" alt="about-image" />
                </div>
              </div>
              <div className="col-md-7 col-lg-6">
                <div className="txt-block right-column pc-15 mb-40">
                  <h3 className="h4-md txt-color-01"> Taleju Spa & Wellness Center</h3>
                  <p className="txt-color-05">
                    Taleju spa and wellness center is a top-rated spa in Thamel, Kathmandu a place where you can rejuvenate your body, mind, and spirit. We focus on helping you build strong relationships with one of our experienced therapists, who will help you find lasting results through friendly conversation, massage, aromatherapy and more.
                  </p>
                
                  <ul className="txt-list txt-color-05">
                    <li className="list-item">
                      <i className="fas fa-genderless" />
                      <p>At the heart of Kathmandu, our spa and wellness center offers affordable price to health conscious people. We are committed to offering a wide range of relaxing treatments for all ages, such as deep tissue massage, mud body wraps and revitalizing facials. Our experienced team at Taleju spa will make sure to make you feel relaxed and rejuvenated after your visit.
                      </p>
                    </li>
                    <li className="list-item">
                      <i className="fas fa-genderless" />
                      <p>Learn about yourself and your body at the spa, relax with a foot massage and enjoy one of many treatments.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>		
            </div>	   
          </div>	   
        </section>

        <section id="about-4" className="bg-color-02 wide-100 about-section division">
          <div className="container">
            <div className="row">	
              <div className="col-lg-10 offset-lg-1">
                <div className="section-title mb-60 text-center">	
                  <h3 className="h3-xl txt-color-01">Welcome To Massage & Wellness Center</h3>	
                  <p className="p-lg txt-color-05">If you’re looking for a place to relax and destress,Taleju Spa & Wellness Center is here to help. We are dedicated in providing the ultimate relaxation experience with massage therapy or spa treatments.
                  </p>
                </div>	
              </div>
            </div>
            <div className="row d-flex align-items-center bg-color-05">
              <div className="col-lg-6 a4-img">
                <div className="img-block">
                  <img className="img-fluid" src="/assets/images/services/hot-stone -therapy.jpg" alt="about-image" />	
                </div>
              </div>
              <div className="col-lg-6">
                <div id="a4-1" className="abox-2">
                  <h4 className="h4-sm txt-color-01">Hot Stone Therapy</h4>
                  <ul className="txt-list txt-color-01">
       
                    <li className="list-item">
                      <i className="fas fa-genderless" />
                      <p>Stressed out? Hot Stone Therapy has the unique ability to help reduce muscle tension and increase overall blood flow. When heated, smooth stones are placed on certain points on the body to warm loosed tight muscles & balance energy centers in the body. Holding heated stones & applying gentle pressure gives the warmth that is comforting. 
                      </p>
                    </li>
                    <li className="list-item">
                      <i className="fas fa-genderless" />
                      <p>Hot Stone Therapy is great for muscles tension and improves circulation in the body.
                      </p>
                    </li>
                  </ul>		
                  <a href="#" className="btn btn-md btn-color-02 tra-01-hover mt-20">Read More</a>
                </div>
              </div>
            </div>
            <div className="abox-2-wrapper">
              <div className="row d-flex align-items-center">
                <div className="col-lg-6 bg-color-03 white-color">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-5 col-lg-6 a4-img">					 				
                      <div className="img-block">
                        <img className="img-fluid" src="/assets/images/services/deep_tissue_massage_sq.jpg" alt="about-image" />	
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-6">
                      <div id="a4-2" className="abox-2">
                        <h5 className="h5-md">Deep tissue massage</h5>
                        <p>It is one kind of massage that focuses on the deeper layers of muscle tissues using manual pressure and slow movement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 bg-color-04 white-color">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-5 col-lg-6 a4-img">					 				
                      <div className="img-block">
                        <img className="img-fluid" src="/assets/images/image-08.jpg" alt="about-image" />	
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-6">
                      <div id="a4-3" className="abox-2">
                        <h5 className="h5-md">Aromatherapy</h5>
                        <p>An aromatherapy massage is massage therapy with the addition of one or more scented plants oils called essential oils to address a specific need. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  	
            </div>	
          </div>	   		
        </section>
    </>
  )
}

export default AboutSection