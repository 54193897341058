import React, {useState, useEffect} from 'react'


function OurServices() {
  const [data,setData]=useState([]);
  let myData = "";
  const getData=()=>{
    fetch('assets/data/services.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        // console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        // console.log(myJson);
        setData(myJson)
      });
  }
  useEffect(()=>{
    getData()
  },[])
  return (
  <section id="services-10" className="bg-color-01 wide-60 services-section division">
    <div className="container">
      <div className="row">	
        <div className="col-lg-10 offset-lg-1">
          <div className="section-title mb-60 text-center">	
            <h3 className="h3-xl txt-color-01">Our Popular Services</h3>	
            <p className="p-lg txt-color-05">We believe that the health and happiness of our guests is our top priority, so we strive to make every visit a relaxing experience.
            </p>
          </div>	
        </div>
      </div>

      <div className="sbox-10-wrapper">
        <div className="row">
        {data.map((mydata, index) => (
              <div className="col-md-6 col-lg-3" key={index}>
                <div className="sbox-10 bg-white text-center">
                  <div className="sbox-10-img">
                    <div className="hover-overlay"> 
                      <img className="img-fluid" src={mydata.mainImage} alt="service-image" />	
                    </div>
                  </div>
                  <div className="sbox-10-txt">
                    <h5 className="h5-sm txt-color-01">{mydata.title}</h5>
                    <p className="txt-color-05">{mydata.summary}</p>	
                    <a href={"service/"+mydata.slug} className="btn p-sm btn-tra-01 color-02-hover">Find Out More</a> 
                  </div>
                </div>							
              </div>
            ))}

        </div>  	
      </div>	
    </div>	   
  </section>	

  )
}

export default OurServices