import React from 'react'

function Pricing() {
  return (
    <section id="pricing-1" className="bg-color-01 wide-60 pricing-section division">
    <div className="container">
      <div className="row">	
        <div className="col-lg-10 offset-lg-1">
          <div className="section-title mb-60 text-center">	
            <h3 className="h3-xl txt-color-01">Best value for money spa in Kathmandu</h3>	
            <p className="p-lg txt-color-05">We offer the best value for money with excellent service to our customers. We hope that you will enjoy the best spa treatement at our location.
            </p>
          </div>	
        </div>
      </div>
      <div className="pricing-wrapper">
        <div className="row">
          <div className="col-lg-6">
            <div className="pricing-1-table">
              <ul className="pricing-list">
                <li className="pricing-list-txt">
                  <h5 className="h5-md txt-color-01">Standard Massage</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 2500</h4>
                </li>
                <li className="pricing-list-txt">
                  <h5 className="h5-md txt-color-01">Deep Tissue Massage</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 3200</h4>
                </li>
                <li className="pricing-list-txt">
                  <h5 className="h5-md txt-color-01">Swedish Massage</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 2500</h4>
                </li>
                <li className="pricing-list-txt">
                  <h5 className="h5-md txt-color-01">Thai Massage</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 2500</h4>
                </li>
                <li className="pricing-list-txt resp-lst">
                  <h5 className="h5-md txt-color-01">Head Neck & Shoulder Massage</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 1500</h4>
                </li>
                <li className="pricing-list-txt resp-lst">
                  <h5 className="h5-md txt-color-01">Foot Reflexology</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 2000</h4>
                </li>
                <li className="pricing-list-txt resp-lst">
                  <h5 className="h5-md txt-color-01">Facial</h5>
                  <p className="txt-color-05">Standard Package</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 2500</h4>
                </li>
                <li className="pricing-list-txt resp-lst">
                  <h5 className="h5-md txt-color-01">Manicure</h5>
                  <p className="txt-color-05">Complete Package</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 2000</h4>
                </li>

              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="pricing-1-table">
              <ul className="pricing-list">
                <li className="pricing-list-txt">
                  <h5 className="h5-md txt-color-01">Hot Stone Therapy</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 4000</h4>
                </li>
                <li className="pricing-list-txt">
                  <h5 className="h5-md txt-color-01">Aroma Therapy </h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 2500</h4>
                </li>
                <li className="pricing-list-txt">
                  <h5 className="h5-md txt-color-01">Ayurvedic Massage</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 3200</h4>
                </li>
                <li className="pricing-list-txt">
                  <h5 className="h5-md txt-color-01">Spinal Massage</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 1500</h4>
                </li>
                <li className="pricing-list-txt">
                  <h5 className="h5-md txt-color-01">Trekker’s Massage</h5>
                  <p className="txt-color-05">60 Minute Session Packages</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 2500</h4>
                </li>


                <li className="pricing-list-txt resp-lst">
                  <h5 className="h5-md txt-color-01">Pedicure</h5>
                  <p className="txt-color-05">Complete Package</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 2400</h4>
                </li>
                <li className="pricing-list-txt resp-lst">
                  <h5 className="h5-md txt-color-01">Threading</h5>
                  <p className="txt-color-05">Complete Package (Eyebrow. Lips, Face beach etc.)</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 1000</h4>
                </li>
                <li className="pricing-list-txt resp-lst">
                  <h5 className="h5-md txt-color-01">Waxing</h5>
                  <p className="txt-color-05">Starting Price</p>
                  <h4 className="h4-xs pricing-list-amount txt-color-01">Rs. 1000</h4>
                </li>

              </ul>
            </div>
          </div>
        </div>	
      </div>	
    </div>	   
  </section>	
  )
}

export default Pricing