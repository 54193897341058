  import React, { useState } from 'react';
  import { BrowserRouter, Routes, Route } from "react-router-dom";
  import Header from './Components/Header';
  import Footer from './Components/Footer';
  import Home from './Pages/Home';
  import AboutUs from './Pages/AboutUs';
  import Error from './Pages/Error';
  import ContactUs from "./Pages/ContactUs";
  // import Blogs from "./Pages/Blogs";
  import OurServices from "./Pages/OurServices";
  import SingleService from "./Pages/SingleService";
  const UserContext = React.createContext(null);


  function App() {

    const [user, setUser] = useState(null);
  
    return (
      <div id="page" className="page">
        <Header/>
          <BrowserRouter>
            <UserContext.Provider value={user} >
              <Routes>
                  <Route index element={<Home/>} />
                  <Route path ="aboutus" element={<AboutUs/>}/>
                  <Route path ="contactus" element={<ContactUs/>}/>
                  {/* <Route path ="blogs" element={<Blogs/>}/> */}
                  <Route path ="services" element={<OurServices/>}/>
                  <Route path ="service/:slug" element={<SingleService/>}/>
                  <Route path ="*" element={<Error/>}/>
              </Routes>
            </UserContext.Provider>
          </BrowserRouter>
        <Footer/>
      </div>
    );
  }

export default App;