import React from 'react'

function ContactUs() {
  return (
    <div className="inner-page-wrapper">	
          <section id="contacts-page" className="bg-fixed wide-100 page-hero-section division">
            <div className="container">	
              <div className="row">	
                <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
                  <div className="hero-txt text-center white-color">
                    <h2 className="h2-lg">Contact Us</h2>
                    <p className="p-lg">For detail information, quotation, appointment please do contact us </p>
                  </div>
                </div>	
              </div>
              <div id="breadcrumb">
                <div className="row">						
                  <div className="col">
                    <div className="breadcrumb-nav">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><a href="/">Home</a></li>
                          <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </section>	

          <section id="contacts-3" className="bg-color-01 wide-60 contacts-section division">				
            <div className="container">
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title mb-60 text-center">	
                    <h3 className="h3-xl txt-color-01">Contact Us</h3>	
                  </div>	
                </div>
              </div>
              <div className="row">	
                <div className="col-md-12">
                  <div className="google-map mb-80">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14128.805120615652!2d85.3132096!3d27.711071!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf43776cc913848a0!2sTaleju%20Boutique%20Hotel!5e0!3m2!1sen!2snp!4v1667293514398!5m2!1sen!2snp" width={600} height={450} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-7 col-lg-8">
                  <h4 className="h4-xs txt-color-01">Send A Message</h4>
                  <p className="txt-color-05">If you want to ask anything just fill in the form below and send us.</p>
                  <div className="form-holder">
                    <form name="contactform" className="row contact-form">
                      <div className="col-lg-6">
                        <input type="text" name="name" className="form-control name" placeholder="Your Name*" /> 
                      </div>
                      <div className="col-lg-6">
                        <input type="email" name="email" className="form-control email" placeholder="Email Address*" /> 
                      </div>
                      <div className="col-md-12">
                        <input type="text" name="subject" className="form-control subject" placeholder="What's this about?" /> 
                      </div>
                          
                      <div className="col-md-12">
                        <textarea name="message" className="form-control message" rows={6} placeholder="Your Message ..." defaultValue={""} />
                      </div> 
                    
                      <div className="col-md-12 mt-5 text-right">  
                        <button type="submit" className="btn btn-md btn-color-02 tra-02-hover submit">Send Message</button> 
                      </div>
                     
                      <div className="col-md-12 contact-form-msg text-center">
                        <div className="sending-msg"><span className="loading" /></div>
                      </div>	
                    </form>	
                  </div>	
                </div>
                <div className="col-md-5 col-lg-4">
                  <div className="contacts-info mb-40">
                    
                    <h4 className="h4-xs txt-color-01">Contact Details</h4>
                   	
                    <p className="txt-color-05"> For detail information, quotation, appointment please do contact us. </p>
                    <div className="cbox-1 mt-25 mb-25">
                    <h5 className="h5-sm txt-color-01">Our Location</h5>													
                    <p className="p-md txt-color-05">Jyatha, Thamel</p>
                    <p className="p-md txt-color-05">Kathmandu, Nepal</p>
                    </div>
                    
                    <div className="cbox-1 mb-25">
                    <h5 className="h5-sm txt-color-01">Contact Info</h5>	
                    <p className="p-md txt-color-05"><span>Phone :</span> +977 1-5360655</p>
                    <p className="p-md txt-color-05"><span>Email :</span> <a href="mailto:hello@talejuspa.com">hello@talejuspa.com</a></p>
                    </div>
                   
                    <div className="cbox-1">
                    <h5 className="h5-sm txt-color-01">Working Hours</h5>	
                    <p className="p-md txt-color-05"><span>Sun – Fri :</span> 9:00 AM - 7:00 PM</p>
                    <p className="p-md txt-color-05"><span>Saturday :</span> Appointments Only</p>
                    </div>
                  </div>	
                </div>	
              </div>	
            </div>	   	
          </section>	
  
          <section id="banner-5" className="bg-fixed bg-image banner-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                <div className="col-lg-6 offset-lg-3">
                  <div className="banner-5-txt text-center">
                    <h2 className="h2-xl txt-color-05">Get 10% Off</h2>
                    <h3 className="h3-xs txt-color-01">For Your First Visit</h3>
                    <p className="p-md txt-color-05">We care for your well being.
                    </p>
                    <a href="booking.html" className="btn btn-md btn-color-02 color-01-hover">Book Now</a>
                  </div>
                </div>		
              </div>	  
            </div>	   
          </section>	
    </div>
  )
}

export default ContactUs
