import React from 'react'

function OpeningHours() {
  return (
    <section id="about-5" className="bg-color-01 pt-10 pb-20 rel about-section division">
    <div className="container">
      <div className="row d-flex align-items-center m-row">
        <div className="about-5-banner bg-color-09 white-color m-bottom">
          <h5 className="h5-xl">Opening Hours:</h5>
          <p>We are open Sunday-Friday. If you would like to book an appointment, please use our website, phone or walk in.</p>
          <ul className="simple-list w-hours mb-20">
            <li className="list-item">
              <p>Sun – Fri : 9:00 AM - 7:00 PM</p>
            </li>
            <li className="list-item">
              <p>Saturday : Booking Only  </p>
            </li>
          </ul>		
          <p> Booking is based on availability.</p>
          <a href="/contactus" className="btn btn-md btn-tra-white white-hover">Book Now</a>
        </div>
        <div className="col-md-12 col-lg-10 offset-lg-2 m-top">
          <div className="about-5-img">
            <img className="img-fluid" src="/assets/images/image-13.jpg" alt="about-image" />	
          </div>
        </div>
      </div>    
    </div>     		
  </section>	
  )
}

export default OpeningHours