import React from 'react'

function Heroslider() {
  return (
    <section id="hero-1" className="hero-section division">
    <div className="slider">
      <ul className="slides">
        <li id="slide-1">
          <img src="/assets/images/slider/hot-stone-therapy.jpg" alt="slide-background" />
          <div className="caption d-flex align-items-center left-align">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="caption-txt white-color">
                    <h2>Hot Stone Therapy</h2>
                    <p>Holding heated stones & applying gentle pressure gives the warmth that is comforting.</p>
                    <a href="/contactus" className="btn btn-md btn-color-02 tra-white-hover">Book an Appointment</a>
                  </div>
                </div>
              </div>  
            </div>  
          </div>	
        </li>	
        <li id="slide-2">
          <img src="/assets/images/slider/deep-tissue-massage.jpg" alt="slide-background" />
          <div className="caption d-flex align-items-center right-align">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="caption-txt white-color">
                    <h2>Deep Tissue Massage</h2>
                    <p>Deep tissue massage technique that focuses on the deeper layers of muscle tissues.</p>
                    <a href="/contactus" className="btn btn-md btn-color-02 tra-white-hover">Book an Appointment</a>
                  </div>	
                </div>
              </div>  
            </div>  
          </div>	
        </li>	
        <li id="slide-3">
          <img src="/assets/images/slider/trekkers-massage.jpg" alt="slide-background" />
          <div className="caption d-flex align-items-center right-align">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="caption-txt white-color">
                    <h2>Trekker’s Massage</h2>
                    <p>The massage is specially provided to those people who go for the short or long trek.</p>
                    <a href="/contactus" className="btn btn-md btn-color-02 tra-white-hover">Book an Appointment</a>
                  </div>
                </div>
              </div>  
            </div>  
          </div>	
        </li>	
      </ul>
    </div>	
  </section>		
  )
}

export default Heroslider