import React, {useState, useEffect} from 'react'

function OurServices() {

  const [data,setData]=useState([]);
  let myData = "";
  const getData=()=>{
    fetch('assets/data/services.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        // console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        // console.log(myJson);
        setData(myJson)
      });
  }
  useEffect(()=>{
    getData()
  },[])

  return (
    <div className="inner-page-wrapper">

      <section id="contacts-page" className="bg-fixed wide-100 page-hero-section division">
        <div className="container">	
          <div className="row">	
            <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
              <div className="hero-txt text-center white-color">
                <h2 className="h2-lg">Our Services</h2>
                <p className="p-lg">We believe that the health and happiness of our guests is our top priority, so we strive to make every visit a relaxing experience.
                </p>
              </div>
            </div>	
          </div>
          <div id="breadcrumb">
            <div className="row">						
              <div className="col">
                <div className="breadcrumb-nav">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Home</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Our Services </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </section>	

      <section id="services-10" className="bg-color-01 wide-60 services-section division">
      <div className="container">
        <div className="row">	
          <div className="col-lg-10 offset-lg-1">
            <div className="section-title mb-60 text-center">	
              <h3 className="h3-xl txt-color-01">Our Services</h3>	
              <p className="p-lg txt-color-05">Relax and rejuvenate at The Taleju Spa and Wellness Center, where you can enjoy your choice of massages, facials and body treatments.
              </p>
            </div>	
          </div>
        </div>
        <div className="sbox-10-wrapper">
          <div className="row">
            {data.map((mydata, index) => (
              <div className="col-md-6 col-lg-3">
                <div className="sbox-10 bg-white text-center">
                  <div className="sbox-10-img">
                    <div className="hover-overlay"> 
                      <img className="img-fluid" src={mydata.mainImage} alt="service-image" />	
                    </div>
                  </div>
                  <div className="sbox-10-txt">
                    <h5 className="h5-sm txt-color-01">{mydata.title}</h5>
                    <p className="txt-color-05">{mydata.summary}</p>	
                    <a href={"service/"+mydata.slug} className="btn p-sm btn-tra-01 color-02-hover">Find Out More</a> 
                  </div>
                </div>							
              </div>
            ))}
          </div>  	
        </div>	
      </div>	   
      </section>
    </div>
  )
}

export default OurServices