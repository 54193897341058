import React from 'react'
import AboutSection from '../Components/AboutSection';
import AboutExperience from '../Components/AboutExperience';
import OurServices from '../Components/OurServices';
import Reviews from '../Components/Reviews';
import FooterBanner from '../Components/FooterBanner';


function AboutUs() {
  return (
      <div className="inner-page-wrapper">	
        <section id="about-page" className="bg-fixed wide-100 page-hero-section division">
          <div className="container">	
            <div className="row">	
              <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
                <div className="hero-txt text-center white-color">
                  <h2 className="h2-lg">About Taleju Spa </h2>
                  <p className="p-lg"> We privide the best service on spa, massage, beauty, relaxation and overall well being.</p>
                </div>
              </div>	
            </div> 
            <div id="breadcrumb">
              <div className="row">						
                <div className="col">
                  <div className="breadcrumb-nav">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="#">About Us</li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </section>


        <AboutSection/>
        <AboutExperience/>
        <OurServices/>

        <section id="pricing-2" className="bg-color-01 pricing-section division">
          <div className="container">
            <div className="pricing-wrapper">
              <div className="row d-flex align-items-center">
                <div className="col-md-6 col-lg-4">
                  <div className="pricing-2-banner bg-color-09 white-color">
                  <h5 className="h5-xl">Opening Hours:</h5>
                    <p>We are open Sunday-Friday. If you would like to book an appointment, please use our website, phone or walk in.</p>
                    <ul className="simple-list w-hours mb-20">
                      <li className="list-item">
                        <p>Sun – Fri : 9:00 AM - 7:00 PM</p>
                      </li>
                      <li className="list-item">
                        <p>Saturday : Booking Only  </p>
                      </li>
                    </ul>		
                    <p> Booking is based on availability.</p>
                    <a href="/contactus" className="btn btn-md btn-tra-white white-hover">Book Now</a>
                  </div>	
                </div>
                <div id="pb-img" className="col-lg-4">
                  <div className="pricing-2-img text-center">
                    <img className="img-fluid" src="/assets/images/image-05.jpg" alt="pricing-image" />	
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="pricing-2-table bg-color-02">
                    <ul className="pricing-list">
                      <li className="pricing-list-txt">
                        <h6 className="h6-lg txt-color-01">Hot Stone Therapy</h6>
                        <p className="txt-color-05">60 Minutes Session Package</p>
                        <h6 className="h6-lg pricing-list-amount txt-color-01">Rs. 4000</h6>
                      </li>
                      <li className="pricing-list-txt">
                        <h6 className="h6-lg txt-color-01">Aroma Therapy</h6>
                        <p className="txt-color-05">60 Minutes Session Package</p>
                        <h6 className="h6-lg pricing-list-amount txt-color-01">Rs. 2500</h6>
                      </li>
                      <li className="pricing-list-txt">
                        <h6 className="h6-lgs txt-color-01">Deep Tissue Massage</h6>
                        <p className="txt-color-05">60 Minutes Session Package</p>
                        <h6 className="h6-lg pricing-list-amount txt-color-01">Rs. 3200</h6>
                      </li>
                      <li className="pricing-list-txt">
                        <h6 className="h6-lg txt-color-01">Swedish Massage</h6>
                        <p className="txt-color-05">60 Minutes Session Package</p>
                        <h6 className="h6-lg pricing-list-amount txt-color-01">Rs. 2500</h6>
                      </li>
                      <li className="pricing-list-txt resp-lst">
                        <h6 className="h6-lg txt-color-01">Thai Massage</h6>
                        <p className="txt-color-05">60 Minutes Session Package</p>
                        <h6 className="h6-lg pricing-list-amount txt-color-01">Rs. 2500</h6>
                      </li>
                      <li className="pricing-list-txt resp-lst">
                        <h6 className="h6-lg txt-color-01">Ayurvedic Massage</h6>
                        <p className="txt-color-05">60 Minutes Session Package</p>
                        <h6 className="h6-lg pricing-list-amount txt-color-01">Rs. 3200</h6>
                      </li>
                    </ul>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section id="team-1" className="bg-color-01 wide-60 team-section division">
          <div className="container">
            {/* SECTION TITLE */}	
            <div className="row">	
              <div className="col-lg-10 offset-lg-1">
                <div className="section-title mb-60 text-center">	
                  <h3 className="h3-xl txt-color-01">Relax, You're In Good Hands</h3>	
                  <p className="p-lg txt-color-05">We are dedicated to providing the best services to bring your mind and boday at optimal condition. Our experienced team will be with you every step of the way to help you.
                  </p>
                </div>	
              </div>
            </div>
            {/* TEAM MEMBERS WRAPPER */}
            <div className="tm-wrapper">
              <div className="row">	
                {/* TEAM MEMBER #1 */}
                <div className="col-md-6 col-lg-3">	
                  <div className="team-member">
                    {/* Team Member Photo */}
                    <div className="team-member-photo">
                      <div className="hover-overlay"> 
                        <img className="img-fluid" src="/assets/images/team-1.jpg" alt="team-member-foto" />
                        {/* Social Icons */}
                        <div className="tm-social clearfix">
                          <ul className="text-center clearfix">																		
                            <li><a href="#" className="ico-facebook"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="#" className="ico-twitter"><i className="fab fa-twitter" /></a></li>	
                            <li><a href="#" className="ico-linkedin"><i className="fab fa-linkedin-in" /></a></li>
                          </ul>
                        </div>	
                      </div>
                    </div>
                    {/* Team Member Meta */}		
                    <div className="tm-meta">
                      <h6 className="h6-lg txt-color-01">Stacey Richardson</h6>
                      <span className="txt-color-06">Beauty Expert</span>
                    </div>
                  </div>
                </div>
                {/* TEAM MEMBER #2 */}
                <div className="col-md-6 col-lg-3">	
                  <div className="team-member">
                    {/* Team Member Photo */}
                    <div className="team-member-photo">
                      <div className="hover-overlay"> 
                        <img className="img-fluid" src="/assets/images/team-2.jpg" alt="team-member-foto" />
                        {/* Social Icons */}
                        <div className="tm-social clearfix">
                          <ul className="text-center clearfix">																		
                            <li><a href="#" className="ico-facebook"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="#" className="ico-twitter"><i className="fab fa-twitter" /></a></li>	
                            <li><a href="#" className="ico-instagram"><i className="fab fa-instagram" /></a></li>
                          </ul>
                        </div>	
                      </div>
                    </div>
                    {/* Team Member Meta */}		
                    <div className="tm-meta">
                      <h6 className="h6-lg txt-color-01">Jennifer Harper</h6>
                      <span className="txt-color-06">SPA Master</span>
                    </div>	
                  </div>
                </div>
                {/* TEAM MEMBER #3 */}
                <div className="col-md-6 col-lg-3">	
                  <div className="team-member">
                    {/* Team Member Photo */}
                    <div className="team-member-photo">
                      <div className="hover-overlay"> 
                        <img className="img-fluid" src="/assets/images/team-3.jpg" alt="team-member-foto" />
                        {/* Social Icons */}
                        <div className="tm-social clearfix">
                          <ul className="text-center clearfix">																		
                            <li><a href="#" className="ico-facebook"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="#" className="ico-twitter"><i className="fab fa-twitter" /></a></li>	
                            <li><a href="#" className="ico-linkedin"><i className="fab fa-linkedin-in" /></a></li>
                          </ul>
                        </div>	
                      </div>
                    </div>
                    {/* Team Member Meta */}		
                    <div className="tm-meta">
                      <h6 className="h6-lg txt-color-01">Rodney Stratton</h6>
                      <span className="txt-color-06">Beautician</span>
                    </div>
                  </div>
                </div>
                {/* TEAM MEMBER #4 */}
                <div className="col-md-6 col-lg-3">	
                  <div className="team-member">
                    {/* Team Member Photo */}
                    <div className="team-member-photo">
                      <div className="hover-overlay"> 
                        <img className="img-fluid" src="/assets/images/team-4.jpg" alt="team-member-foto" />
                        {/* Social Icons */}
                        <div className="tm-social clearfix">
                          <ul className="text-center clearfix">																		
                            <li><a href="#" className="ico-facebook"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="#" className="ico-twitter"><i className="fab fa-twitter" /></a></li>	
                            <li><a href="#" className="ico-instagram"><i className="fab fa-instagram" /></a></li>
                          </ul>
                        </div>	
                      </div>
                    </div>
                    <div className="tm-meta">
                      <h6 className="h6-lg txt-color-01">Jane Smith</h6>
                      <span className="txt-color-06">Beauty Therapist</span>
                    </div>	
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Reviews/>
        <FooterBanner/>

      </div>
  )
}

export default AboutUs