import React from 'react'

const FooterBanner = () => {
  return (
    <section id="banner-5" className="bg-fixed bg-image banner-section division">
        <div className="container">
        <div className="row d-flex align-items-center">
            <div className="col-lg-6 offset-lg-3">
            <div className="banner-5-txt text-center">
                <h2 className="h2-xl txt-color-05">10% Off on </h2>
                <h3 className="h3-xs txt-color-01">Couple / Group Package</h3>
                <p className="p-md txt-color-05">Register now and get exciting offers on group package.
                </p>
                <a href="/contactus" className="btn btn-md btn-color-02 color-01-hover">Book Now</a>
            </div>
            </div>		
        </div>	  	
        </div>	   
    </section>
  )
}

export default FooterBanner