import React from 'react'
import Heroslider from '../Components/Heroslider';
import AboutSection from '../Components/AboutSection';
import FeelSpecialSection from '../Components/FeelSpecialSection';
import FooterBanner from '../Components/FooterBanner';
// import BlogSection from '../Components/BlogSection';
// import BannerAppointment from '../Components/BannerAppointment';
import OurServices from '../Components/OurServices';
import OpeningHours from '../Components/OpeningHours';
import Gallery from '../Components/Gallery';
import Pricing from '../Components/Pricing';
import Reviews from '../Components/Reviews';
// import Sercices2 from '../Components/Services2';
import BookingSection from '../Components/BookingSection';
import AboutExperience from '../Components/AboutExperience';

function Home() {
  return (
    <>
        <Heroslider/>
        <AboutSection/>
        <FeelSpecialSection/>
        <OurServices/>
        <OpeningHours/>
        <Gallery/>
        <Pricing/>
        {/* <Sercices2/> */}
        <Reviews/>
        <BookingSection/>
        <AboutExperience/>
        {/* <BannerAppointment /> */}
        {/* <BlogSection/> */}
        <FooterBanner/>
    </>
  )
}

export default Home