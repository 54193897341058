import React from 'react'

function Gallery() {
  return (
    <section id="gallery-1" className="bg-color-01 wide-80 gallery-section division">
    <div className="container">
      <div className="row">	
        <div className="col-lg-10 offset-lg-1">
          <div className="section-title mb-50 text-center">	
            <h3 className="h3-xl txt-color-01"> Gallery</h3>
          </div>	
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="masonry-filter mb-40">
            <button data-filter="*" className="is-checked">All</button>
            <button data-filter=".spa">Body Treatments</button>
            <button data-filter=".massage">Massage</button>
            <button data-filter=".aroma">Aroma Therapy</button>
            <button data-filter=".herbs">Herbs</button>
          </div>
        </div>
      </div>
      <div className="row">	
        <div className="col-md-12 gallery-items-list">
          <div className="masonry-wrap grid-loaded">
            <div className="masonry-item spa massage">
              <div className="hover-overlay"> 
                <img className="img-fluid" src="/assets/images/gallery/img-1.jpg" alt="gallery-image" />			
                <div className="item-overlay" />
                <div className="image-description white-color">
                  <div className="image-data">
                    <h5 className="h5-sm"><a className="image-link" href="images/gallery/img-1.jpg">Hot Stones Therapy</a></h5>		
                    <p>SPA &amp; Massage Therapy</p>	
                  </div>																										 
                </div> 
              </div>
            </div>		
            <div className="masonry-item spa">
              <div className="hover-overlay"> 
                <img className="img-fluid" src="/assets/images/gallery/img-09.jpg" alt="gallery-image" />			
                <div className="item-overlay" />				
                <div className="image-description white-color">
                  <div className="image-data">
                    <h5 className="h5-sm"><a className="image-link" href="images/gallery/img-09.jpg">Relaxing Massage</a></h5>		
                    <p>SPA &amp; Wellness</p>	
                  </div>																										 
                </div> 
              </div>
            </div>		
            <div className="masonry-item aroma">
              <div className="hover-overlay"> 
                <img className="img-fluid" src="/assets/images/gallery/img-2.jpg" alt="gallery-imagee" />			
                <div className="item-overlay" />				
                <div className="image-description white-color">
                  <div className="image-data">
                    <h5 className="h5-sm"><a className="image-link" href="images/gallery/img-2.jpg">Supreme Skincare</a></h5>		
                    <p>Aroma &amp; Skin Care</p>	
                  </div>																										 
                </div> 
              </div>
            </div>		
            <div className="masonry-item spa">
              <div className="hover-overlay"> 
                <img className="img-fluid" src="/assets/images/gallery/img-5.jpg" alt="gallery-image" />			
                <div className="item-overlay" />				
                <div className="image-description white-color">
                  <div className="image-data">
                    <h5 className="h5-sm"><a className="image-link" href="images/gallery/img-5.jpg">Hot Stones Therapy</a></h5>		
                    <p>SPA &amp; Aromatherapy</p>	
                  </div>																										 
                </div> 
              </div>
            </div>	
            <div className="masonry-item massage herbs">
              <div className="hover-overlay"> 
                <img className="img-fluid" src="/assets/images/gallery/img-3.jpg" alt="gallery-image" />			
                <div className="item-overlay" />				
                <div className="image-description white-color">
                  <div className="image-data">
                    <h5 className="h5-sm"><a className="image-link" href="images/gallery/img-3.jpg">Thai Massage</a></h5>		
                    <p>Herbs &amp; Skin Care</p>	
                  </div>																										 
                </div> 
              </div>
            </div>	
            <div className="masonry-item herbs">
              <div className="hover-overlay"> 
                <img className="img-fluid" src="/assets/images/gallery/img-4.jpg" alt="gallery-image" />			
                <div className="item-overlay" />				
                <div className="image-description white-color">
                  <div className="image-data">
                    <h5 className="h5-sm"><a className="image-link" href="images/gallery/img-4.jpg">Relaxing Massage</a></h5>		
                    <p>Herbs &amp; Massage Therapy</p>	
                  </div>																										 
                </div>  
              </div>
            </div>	
            <div className="masonry-item aroma herbs">
              <div className="hover-overlay"> 
                <img className="img-fluid" src="/assets/images/gallery/img-10.jpg" alt="gallery-image" />			
                <div className="item-overlay" />				
                <div className="image-description white-color">
                  <div className="image-data">
                    <h5 className="h5-sm"><a className="image-link" href="images/gallery/img-10.jpg">Facial Spa</a></h5>		
                    <p>Herbs &amp; Skin Care</p>	
                  </div>																										 
                </div>  
              </div>
            </div>	
            <div className="masonry-item aroma">
              <div className="hover-overlay"> 
                <img className="img-fluid" src="/assets/images/gallery/img-11.jpg" alt="gallery-image" />			
                <div className="item-overlay" />				
                <div className="image-description white-color">
                  <div className="image-data">
                    <h5 className="h5-sm"><a className="image-link" href="images/gallery/img-11.jpg">Scrub Massage</a></h5>		
                    <p>Aroma &amp; Massage Therapy</p>	
                  </div>																										 
                </div> 
              </div>
            </div>	
            <div className="masonry-item spa massage">
              <div className="hover-overlay"> 
                <img className="img-fluid" src="/assets/images/gallery/img-6.jpg" alt="gallery-image" />			
                <div className="item-overlay" />				
                <div className="image-description white-color">
                  <div className="image-data">
                    <h5 className="h5-sm"><a className="image-link" href="images/gallery/img-6.jpg">Deep Tissue Massage</a></h5>		
                    <p>SPA &amp; Massage Therapy</p>	
                  </div>																										 
                </div> 
              </div>
            </div>	
          </div>
        </div>	
      </div>	 
    </div>	   	
  </section>	
  )
}

export default Gallery