import React from 'react'

function error() {
  return (
    <div>
    <div className="inner-page-wrapper">	
      <section id="about-page" className="bg-fixed wide-100 page-hero-section division">
        <div className="container">	
          <div className="row">	
            <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
              <div className="hero-txt text-center white-color">
                <h2 className="h2-lg">Oops! Could Not Found </h2>
              </div>
            </div>	
          </div> 
          <div id="breadcrumb">
            <div className="row">						
              <div className="col">
                <div className="breadcrumb-nav">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Return to Home Page</a></li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </section>
    </div>
  </div>
  )
}

export default error