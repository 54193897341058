import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser'; 

function SingleService() {
  const [data,setData]=useState([]);
  const { slug } = useParams();
  // console.log(slug);
  let myData = "";
  const getData=()=>{
    fetch(window.location.origin.toString()+'/assets/data/services.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        // console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        // console.log(myJson);
        setData(myJson)
      });
  }
  useEffect(()=>{
    getData()
  },[])

  // console.log(data);

  data.forEach (function(value, key) {
    // console.log(value);
    if(value.slug == slug){
    myData= value;
    }
  })


  return (
    <div>
      <div id="page" className="page">
      
        <div className="inner-page-wrapper">
          <section id="service-details-page" className="bg-fixed wide-100 division" style={
            {backgroundImage:`url(${process.env.PUBLIC_URL+myData.coverImage})`,
            backgroundColor:'#46170F'
          } 
            }
            >
            <div className="container">	
              <div className="row">	
                <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
                  <div className="hero-txt text-center white-color">
                    <h2 className="h2-lg">{myData.title}</h2>
                    <p className="p-lg">{myData.summary}</p>
                  </div>
                </div>	
              </div>    
              <div id="breadcrumb">
                <div className="row">						
                  <div className="col">
                    <div className="breadcrumb-nav">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><a href="/">Home</a></li>
                          <li className="breadcrumb-item"><a href="/services">Services</a></li>
                          <li className="breadcrumb-item active" aria-current="page">{myData.title}</li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div> 
              </div>	
            </div>	    
          </section>	
          <section id="about-10" className="bg-color-01 wide-80 about-section division">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="txt-block pr-30">
                    <div className="txt-color-05" dangerouslySetInnerHTML = {{__html :  myData.introduction }}></div>

                    <div className="inner-img">
                      <img className="img-fluid" src={myData.mainImage} alt={myData.title} />
                    </div>
                    <div  className="txt-color-05" dangerouslySetInnerHTML = {{__html : myData.description}}></div>

                  </div>
                </div>	
                <aside id="sidebar" className="col-lg-4">
                  <div id="sb-box" className="sidebar-div bg-color-02 mb-50">
                    <div className="sb-box-txt">
                      <h5 className="h5-sm txt-color-01">{myData.title} Pricing</h5>
                      <div className="sb-box-price mt-15 mb-25 txt-color-01">
                      {myData.price && myData.price.length > 0 && myData.price.map((myPrice, index) => (
                        <span key={index} >{myPrice.type } - {myPrice.price}</span>
                      ))}
                       
                      </div>
                      <div className="sb-box-link">
                        <a href="/contactus" className="btn btn-color-02 tra-01-hover">Book an Appointment</a>
                      </div>
                    </div>
                  </div>	
                  <div className="sidebar-table sidebar-div mb-50">
                    <h5 className="h5-sm txt-color-01">Opening Time</h5>
                    <p className="txt-color-05">to book an appointment, please use our website, phone or walk in</p>
                    <table className="table txt-color-05">
                      <tbody>
                        <tr>
                          <td>Sun – Fri</td>
                          <td> - </td>
                          <td className="text-right">9:00 AM - 7:00 PM</td>
                        </tr>
                        <tr>
                          <td>Saturday</td>
                          <td> - </td>
                          <td className="text-right">Appointments Only</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>	
                  <div className="image-widget sidebar-div mb-50">
                    <h5 className="h5-sm txt-color-01">Image Widget</h5>
                    <a href="#"><img className="img-fluid" src="/assets/images/blog/image-widget.jpg" alt="image-widget" /></a>	
                  </div>
                </aside>   
              </div>	
            </div>	   
          </section>	
        </div>	
      </div>

    </div>
  )
}

export default SingleService