import React from 'react'

function Reviews() {
  return (
    
    <section id="reviews-1" className="bg-color-01 pb-100 reviews-section division">
    <div className="container">
      <div className="row">	
        <div className="col-lg-10 offset-lg-1">
          <div className="section-title mb-60 text-center">	
            <h3 className="h3-xl txt-color-01">What our clients says about Taleju Spa.</h3>	
            
          </div>	
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">					
          <div className="owl-carousel owl-theme reviews-wrapper">
            <div className="review-1">
              <div className="testimonial-avatar">
                <img src="/assets/images/review-author-1.jpg" alt="testimonial-avatar" />
              </div>
              <div className="author-data txt-color-01">
                <h6 className="h6-sm">Kelly Walke</h6>	
                <p>Housewife</p>									
              </div>
              <div className="review-1-txt txt-color-05">
                <p>Sagittis congue etiam sapien sem accumsan suscipit egestas lobortis magna, porttitor
                  sodales vitae aenean mauris tempor risus lectus 								   
                </p>															
              </div>	
            </div>
            <div className="review-1">
              <div className="testimonial-avatar">
                <img src="/assets/images/review-author-2.jpg" alt="testimonial-avatar" />
              </div>
              <div className="author-data txt-color-01">
                <h6 className="h6-sm">Linda Ferell</h6>
                <p>Designer</p>									
              </div>
              <div className="review-1-txt txt-color-05">
                <p>Sapien sem accumsan  vitae purus diam integer congue magna undo. Magna, sodales vitae
                  aenean mauris tempor risus lectus aenean magna ipsum vitae purus	vitae						   
                </p>															
              </div>	
            </div>
            <div className="review-1">
              <div className="testimonial-avatar">
                <img src="/assets/images/review-author-3.jpg" alt="testimonial-avatar" />
              </div>
              <div className="author-data txt-color-01">
                <h6 className="h6-sm">Evelyn Martinez</h6>
                <p>Journalist</p>									
              </div>
              <div className="review-1-txt txt-color-05">
                <p>Etiam sapien sem accumsan sagittis congue. Suscipit egestas at lobortis magna, porttitor
                  sodales vitae aenean mauris tempor risus lectus aenean diam aenean mauris								   
                </p>															
              </div>	
            </div>
            <div className="review-1">
              <div className="testimonial-avatar">
                <img src="/assets/images/review-author-4.jpg" alt="testimonial-avatar" />
              </div>
              <div className="author-data txt-color-01">
                <h6 className="h6-sm">Laura Merino</h6>
                <p>Fashion Designer</p>								
              </div>
              <div className="review-1-txt txt-color-05">
                <p>Egestas egestas magna ipsum vitae purus efficitur ipsum primis in cubilia laoreet augue
                  congue. An egestas lobortis magna, sodales vitae 								   
                </p>															
              </div>	
            </div>
            <div className="review-1">
              <div className="testimonial-avatar">
                <img src="/assets/images/review-author-5.jpg" alt="testimonial-avatar" />
              </div>
              <div className="author-data txt-color-01">
                <h6 className="h6-sm">Elizabeth Ross</h6>
                <p>Biologist</p>									
              </div>
              <div className="review-1-txt txt-color-05">
                <p>An orci nullam tempor sapien, eget orci gravida donec enim ipsum porta justo integer and
                  odio velna auctor. Egestas magna ipsum vitae purus ipsum primis in laoreet augue								   
                </p>															
              </div>
            </div>
            <div className="review-1">
              <div className="testimonial-avatar">
                <img src="/assets/images/review-author-6.jpg" alt="testimonial-avatar" />
              </div>
              <div className="author-data txt-color-01">
                <h6 className="h6-sm">Carmen M. Garcia</h6>	
                <p>Graphic Designer</p>									
              </div>
              <div className="review-1-txt txt-color-05">
                <p>Mauris donec ociis et magnis sapien an etiam sapien sem sagittis congue augue. An orci nullam
                  tempor sapien, eget orci gravida donec porta							   
                </p>															
              </div>	
            </div>
            <div className="review-1">
              <div className="testimonial-avatar">
                <img src="/assets/images/review-author-7.jpg" alt="testimonial-avatar" />
              </div>
              <div className="author-data txt-color-01">
                <h6 className="h6-sm">Penelopa M.</h6>	
                <p>Manager</p>								
              </div>
              <div className="review-1-txt txt-color-05">
                <p>At sagittis congue augue egestas egestas magna ipsum vitae purus ipsum primis in cubilia
                  laoreet augue diam ociis nullam tempor 		   
                </p>															
              </div>	
            </div>
          </div>
        </div>									
      </div>	 
    </div>	   
  </section>	
  )
}

export default Reviews