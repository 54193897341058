import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

function Header() {
  return (
    <header id="header" className="header white-menu navbar-dark">
          <div className="header-wrapper">
            <div className="wsmobileheader clearfix">	  	
              <span className="smllogo"><img src="/assets/images/logo-01.png" width={170} height={50} alt="mobile-logo" /></span>
              <a id="wsnavtoggle" href="/" className="wsanimated-arrow"><span /></a>	
            </div>
            <div className="wsmainfull menu clearfix">
              <div className="wsmainwp clearfix">
                <div className="desktoplogo"><a href="/" className="logo-black"><img src="/assets/images/logo-01.png" width={170} height={50} alt="header-logo" /></a></div>
                <div className="desktoplogo"><a href="/" className="logo-white"><img src="/assets/images/logo-white.png" width={170} height={50} alt="header-logo" /></a></div>
                <nav className="wsmenu clearfix">
                  <ul className="wsmenu-list">
                  <li className="nl-simple" aria-haspopup="true"><a href="/">Home</a></li> 
                  <li className="nl-simple" aria-haspopup="true"><a href="/aboutus">About Us</a></li> 
                  <li className="nl-simple"><a href="/services">Our Services </a></li>	 
                  {/* <li className="nl-simple" aria-haspopup="true"><a href="/blogs">Blogs </a></li>  */}
                  <li className="nl-simple" aria-haspopup="true"><a href="/contactus">Contact</a></li> 
                  <li className="nl-simple header-phone" aria-haspopup="true">
                      <a href="tel:123456789">
                        <span className="bg-color-09 white-color">
                          <i className="fas fa-phone" />
                        </span>
                        Call : +977 015360655
                      </a>
                    </li> 
                  </ul>
                </nav>
              </div>
            </div>
          </div> 
        </header>
  )
}

export default Header