import React from 'react'

function BookingSection() {
  return (
    <section id="about-6" className="bg-01 about-section division">
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col-md-7 col-lg-6">
          <div className="txt-block">
            <h3 className="h3-lg txt-color-01">Want to Make a Booking or Have a Question?</h3>
            <p className="p-xl txt-color-01">Call us : <span>+977 1 5360655, 5368977</span> or fill out our online booking &amp; equiry 
              form and we will contact you
            </p>
            <a href="/contactus" className="btn btn-md btn-color-02 color-01-hover">Make an Appointment</a>
          </div>
        </div>		
        <div className="col-md-5 col-lg-6">
          <div className="about-6-img">
            <img className="img-fluid" src="/assets/images/image-04.jpg" alt="about-image" />
          </div>
        </div>
      </div>	  	
    </div>     
  </section>	

  )
}

export default BookingSection