import React from 'react'

function Footer() {
  let siteName = "Taleju Spa";
  let address = "Taleju Boutique Hotel <br/>Jyatha, Thamel, Kathmandu";
  let email = "hello@talejuspa.com";
  let phone = "+977 1 5360655, 5368977";
  // let mobile = "+977 985-1027652";

  return (
    <footer id="footer-1" className="bg-color-01 footer division">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-4 col-xl-5">
          <div className="footer-info mb-40">
            <img src="/assets/images/logo-01.png" width={204} height={60} alt="footer-logo" />
            <div className="footer-contacts txt-color-05 mt-25">
              <p dangerouslySetInnerHTML = {{__html : address}}></p> 
              <p className="foo-email">E: <a href="mailto:{email}">{email}</a></p>
              <p>Phone No: {phone}</p>
              {/* <p>Mobile No: {mobile}</p> */}
            </div>
          </div>	
        </div>	
        <div className="col-md-3 col-lg-2">
          <div className="footer-links mb-40">
            <h6 className="h6-lg txt-color-01">About Us</h6>
            <ul className="clearfix txt-color-05">
              <li><p><a href="/aboutus">About Us</a></p></li>		
              <li><p><a href="/contactus">Contact Us</a></p></li>	
              <li><p><a href="/contactus">Appointment</a></p></li>	
              <li><p><a target="_blank" href="https://www.google.com/maps/place/Taleju+Boutique+Hotel/@27.711071,85.3110209,17z/data=!3m1!4b1!4m8!3m7!1s0x39eb18fdfd6e581d:0xf43776cc913848a0!5m2!4m1!1i2!8m2!3d27.711071!4d85.3132096?hl=en">Our Location</a></p></li>
              <li><p><a target="_blank" href="https://taleju.org/">Our Ventures</a></p></li>
            </ul>
          </div>
        </div>
        <div className="col-md-3 col-lg-2">
          <div className="footer-links mb-40">
            <h6 className="h6-lg txt-color-01">Popular Services</h6>
            <ul className="clearfix txt-color-05">									
              <li><p><a href="#">Standard Massage</a></p></li>																	
              <li><p><a href="#">Hot Stone Therapy</a></p></li>
              <li><p><a href="#">Trekker’s Massage</a></p></li>
              <li><p><a href="#">Aroma Therapy</a></p></li>	
              <li><p><a href="#">Swedish Massage</a></p></li>						
            </ul>
          </div>
        </div>
        <div className="col-md-8 col-lg-4 col-xl-3">
          <div className="footer-form mb-20">
            <h6 className="h6-lg txt-color-01">Subscribe Us:</h6>
            <p className="txt-color-05 mb-20">Stay up to date with our latest news and our new products</p>
            <form className="newsletter-form">
              <div className="input-group">
                <input type="email" className="form-control" placeholder="Email Address" required id="s-email" />								
                <span className="input-group-btn">
                  <button type="submit" className="btn txt-color-06">
                    <i className="far fa-arrow-alt-circle-right" />
                  </button>
                </span>
              </div>
              <label htmlFor="s-email" className="form-notification" />
            </form>
          </div>
        </div>	
      </div>	  
      <div className="bottom-footer txt-color-05">
        <div className="row d-flex align-items-center">
          <div className="col-lg-6">
            <div className="footer-copyright">
              <p>© {new Date().getFullYear()} {siteName}. All Rights Reserved</p>
            </div>
          </div>
          <div className="col-lg-6">
            <ul className="bottom-footer-list text-right clearfix">
              <li><p className="first-list-link"><a href="www.facebook.com"><i className="fab fa-facebook-f" /> Facebook</a></p></li>	
              <li><p><a href="www.twitter.com"><i className="fab fa-twitter" /> Twitter</a></p></li>
              <li><p><a href="www.instagram.com"><i className="fab fa-instagram" /> Instagram</a></p></li>
            </ul>
          </div>
        </div>  
      </div>	
    </div>	   										
  </footer>	
  )
}

export default Footer