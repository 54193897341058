import React from 'react'

function FeelSpecialSection() {
    return (
      <section id="banner-2" className="bg-fixed wide-100 banner-section division">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-5">
            <div className="banner-2-txt white-color">
              <h2>Fusion Of</h2>
              <h3 className="h3-sm">Ancient Knowledge with Modern Techniques</h3>
              <p className="p-md">We believe in the power of wellness and the art of ancient knowledge. We are committed to bringing you a beautiful, natural experience that will leave you feeling refreshed, renewed and ready for life's journey. 
              </p>
              <a href="pricing.html" className="btn btn-md btn-color-02 tra-white-hover">Find Out More</a>
            </div>
          </div>	
        </div>    	
      </div>	   	
    </section>	
  
    )
}

export default FeelSpecialSection