import React from 'react'

function AboutExperience() {
  return (
    <section id="about-3" className="bg-color-01 wide-60 about-section division">
    <div className="container">
      <div className="row">	
        <div className="col-lg-10 offset-lg-1">
          <div className="section-title mb-60 text-center">	
            <h3 className="h3-xl txt-color-01">Well trained and experienced therapist</h3>	
            <p className="p-lg txt-color-05">Enjoy the feeling of inner peace with this gentle spa and massage from well trained and experienced therapist.
            </p>
          </div>	
        </div>
      </div>
      <div className="row">	
        <div className="col-md-12">
          <div className="img-block">
            <img className="img-fluid" src="/assets/images/image-12.jpg" alt="about-image" />
          </div>
        </div>
      </div>
      <div className="abox-1-wrapper">
        <div className="row d-flex align-items-center">
          <div className="col-md-4">
            <div className="abox-1 mb-40">
              <div className="abox-1-ico ico-75 txt-color-03">
                <span className="flaticon-relax-stones" />
              </div>
              <div className="abox-1-txt">
                <h5 className="h5-xs txt-color-01">De-Stress</h5>
                <p className="txt-color-05">When you need to de-stress, our spa and massage services offer the perfect way to relax the mind, body and spirit.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="abox-1 mb-40">
              <div className="abox-1-ico ico-75 txt-color-03">
                <span className="flaticon-herbal-2" />
              </div>
              <div className="abox-1-txt">
                <h5 className="h5-xs txt-color-01">Anti-Aging.</h5>
                <p className="txt-color-05">Spa and massage helps to prevent wrinkles and other skin problems, including age spots, skin damage and loss of elasticity.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="abox-1 mb-40">
              <div className="abox-1-ico ico-75 txt-color-03">
                <span className="flaticon-gift" />
              </div>
              <div className="abox-1-txt">
                <h5 className="h5-xs txt-color-01">Relive Pain</h5>
                <p className="txt-color-05">Your body will physically relax and feel rejuvenated, stress is alleviated and you may feel more energized.</p>
              </div>
            </div>
          </div>
        </div>   
      </div>	  
    </div>	   
  </section>	
  )
}

export default AboutExperience